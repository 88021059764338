import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'antd';

import './index.scss';

function RPButton({
  right,
  center,
  grey,
  ghost,
  danger,
  children,
  gradient,
  className,
  withArrowIcon,
  ...props
}) {
  const btnKlassName = () => {
    let klassName;

    if (danger) {
      klassName = 'btn-dangerous';
    } else if (grey) {
      klassName = 'btn-grey';
    } else if (gradient) {
      klassName = 'btn-gradient';
    } else if (ghost) {
      klassName = 'btn-ghost';
    } else {
      klassName = 'btn-default';
    }

    return klassName;
  };

  const renderButtonContent = () => {
    if (withArrowIcon) {
      return (
        <div className="btn-gradient__content">
          {children}
          <div className="btn-gradient__icon" />
        </div>
      );
    }
    return (
      <div className="btn-gradient__content">
        {children}
      </div>
    );

  };

  const rpButtonKlass = `rp-button${right ? ' --right' : ''}${center ? ' --center' : ''}`;

  return (
    <div className={rpButtonKlass}>
      <Button
        {...props}
        className={`${btnKlassName()} ${className || ''}`}
        shape="round"
        size="large"
      >
        {renderButtonContent()}
      </Button>
    </div >
  );
}

RPButton.propTypes = {
  grey: PropTypes.bool,
  ghost: PropTypes.bool,
  right: PropTypes.bool,
  danger: PropTypes.bool,
  center: PropTypes.bool,
  gradient: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  className: PropTypes.string,
  withArrowIcon: PropTypes.bool,
};

export default RPButton;
