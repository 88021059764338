import http from '@/http';
import { notification } from 'antd';
import camelcaseKeys from 'camelcase-keys';
import { paymentLinkApis } from '@/constants/api';

export const getPaymentLinkDetails = async ({ id }) => {
  try {
    const res = await http.get(`${paymentLinkApis.base}/${id}`);

    if (res.status !== 200) {
      return null;
    }

    const { data } = camelcaseKeys(res, { deep: true });
    return data;
  } catch (error) {
    if (error.response.status === 404) {
      return null;
    }

    notification.error({
      message: error.response.data.code,
      description: error.response.data.message,
      placement: 'bottom',
      duration: 5,
      className: 'rp-message',
    });
    return null;
  }
};
