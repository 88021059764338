import React from 'react';
import PropTypes from 'prop-types';
import {
  InputNumber
} from 'antd';

import './index.scss';

function RPInputAmount({
  label,
  hint,
  required,
  value,
  className,
  disabled,
  ...props
}) {
  const renderAmount = () => {
    let klassName = value ? `${className} --has-value` : className;
    klassName = disabled ? `${klassName} --disabled` : klassName;

    return (
      <InputNumber
        className={klassName}
        formatter={(e) => `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(e) => e.replace(/\$\s?|(,*)/g, '')}
        controls={false}
        defaultValue={value}
        disabled={disabled}
        {...props}
      />
    );
  };

  return (
    <div>
      <div className={`rp-input-number-container${disabled ? ' disabled-input' : ''}`}>
        {
          renderAmount()
        }
        <div className="hover-style" />
        {!!label && (
          <label htmlFor='#' className="custom-input__label">
            {label}
            {!!required && (<span className="custom-input__required">*</span>)}
          </label>
        )
        }
      </div>
      {!!hint && <p className="custom-input__hint">{hint}</p>}
    </div>
  );
};

RPInputAmount.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.number,
  className: PropTypes.string,
};

export default RPInputAmount;
