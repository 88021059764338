import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
} from 'antd';

import './index.scss';

function RPInput({
  label,
  hint,
  required,
  textPrefix,
  type,
  value,
  ...props
}) {
  const blockInvalidChar = e => ['e', 'E'].includes(e.key) && e.preventDefault();

  return (
    <div>
      <div className={`rp-input-container ${textPrefix && '--prefix'}`}>
        {
          type === 'number' ?
            <Input
              {...props}
              type={type}
              value={value}
              onKeyDown={blockInvalidChar}
            /> :
            <Input
              type={type}
              value={value}
              {...props}
            />
        }
        {
          textPrefix &&
          <div className="rp-input__prefix">{textPrefix}</div>
        }
        <div className="hover-style" />
        {!!label && (
          <label htmlFor='#' className="custom-input__label">
            {label}
            {!!required && (<span className="custom-input__required">*</span>)}
          </label>
        )
        }
      </div>
      {!!hint && <p className="custom-input__hint">{hint}</p>}
    </div>
  );
};

RPInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  textPrefix: PropTypes.string,
  type: PropTypes.string
};

export default RPInput;
