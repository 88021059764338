import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { generatePath } from 'react-router-dom';
import { usePayModalConfirmConfig } from '@/hooks';

import RPButton from '@/components/RPButton';
import logoBlueIcon from '@/assets/images/logo-blue.svg';
import redXIcon from '@/assets/images/payment-method/red-x-icon.svg';
import { formatter } from '@/utils';
import { ROUTES } from '@/constants';
import { useSelector } from 'react-redux';
import { selectPaymentButtons } from '@/store/paymentButton/paymentButtonSelector';

import './index.scss';

function PaymentFailed({
  transactionData,
  paymentRef,
  refType,
  embedMode = false,
}) {
  const { paymentChannel } = useSelector(selectPaymentButtons);
  const payModalConfirmConfig = usePayModalConfirmConfig({ paymentChannel });
  const {
    amount,
    order,
    orderId,
  } = transactionData;

  const retry = () => {
    if (order && paymentRef) {
      const payload = {
        id: order.id,
        amount: order.amount,
        email: order.email,
        contactNumber: order.contactNumber,
      };
      sessionStorage.setItem(`pay${paymentRef.id}`, JSON.stringify(payload));
      if (refType === 'PaymentPage') {
        window.location = generatePath(ROUTES.PAYMENT_PAGE.VIEW, { id: paymentRef.id });
      }

      if (refType === 'PaymentButton') {
        window.location = generatePath(ROUTES.PAYMENT_BUTTON.VIEW, { id: paymentRef.id });
      }

      if (refType === 'PaymentLink') {
        window.location = generatePath(ROUTES.PAYMENT_LINK.VIEW, { id: paymentRef.id });
      }
    }
  };

  const {
    logo,
    merchant,
  } = paymentRef;

  return (
    <div className='rp-payment-card-container'>
      <div className={`rp-payment-card__heading${embedMode ? ' --embed-mode' : ''}`}>
        <div className='rp-payment-card__heading__actions'>
          {embedMode &&
            <Button
              type='link'
              onClick={() => Modal.confirm(payModalConfirmConfig)}
              className='button-close-x'
            >
              <CloseOutlined style={{ fontSize: 16 }} />
            </Button>
          }
        </div>
        <div className='rp-payment-card__heading__logo-group'>
          <div className='rp-payment-card__heading__logo-item --main-merchant'>
            <img
              src={logoBlueIcon}
              alt="icon"
              width="100%"
              height="100%"
            />
          </div>
          {logo && merchant &&
            <div className='rp-payment-card__heading__logo-item --client-merchant'>
              <img
                src={logo?.url}
                alt="icon"
                width="100%"
                height="100%"
              />
              <p className="mb-0">{merchant?.name}</p>
            </div>
          }
        </div>
        <div className='rp-payment-card__heading__payment-info'>
          <div className='rp-payment-card__heading__payment-info__payment-id'>Payment ID {orderId}</div>
          <div className='rp-payment-card__heading__payment-info__payment-desc'>Amount Payable Now</div>
          <div className='rp-payment-card__heading__payment-info__payment-amount'>
            {!!amount && formatter.formatCurrency(amount / 100)}
          </div>
        </div>
      </div>
      <div className='rp-payment-card__body'>
        <div className="rp-payment-card-wrapper">
          <div className="rp-payment-card__body__fail-container">
            <div className="rp-payment-card__body__fail-icon">
              <img
                src={redXIcon}
                alt="icon"
                width="100%"
                height="100%"
              />
            </div>
            <div className="rp-payment-card__body__fail__content">
              <p>Your payment didn&rsquo;t go through as it was declined by the bank.</p>
              <p>Try another payment method or contact your bank.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='rp-payment-card__footer'>
        <RPButton
          gradient
          center
          onClick={retry}
        >
          <div>Retry</div>
        </RPButton>
      </div>
    </div >
  );
}

PaymentFailed.propTypes = {
  transactionData: PropTypes.object.isRequired,
  paymentRef: PropTypes.object.isRequired,
  refType: PropTypes.string.isRequired,
  embedMode: PropTypes.bool,
};

export default PaymentFailed;
