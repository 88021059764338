import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Form,
  Radio,
  Spin,
} from 'antd';
import { uniqueId } from 'lodash';
import { Icon } from '@iconify/react';
import {
  UserOutlined,
} from '@ant-design/icons';

import HeadingPayMethod from '@/components/PaymentMethod/Methods/Heading';
import airtelLogo from '@/assets/images/payment-method/wallets/airtel-logo.png';
import olaMoneyLogo from '@/assets/images/payment-method/wallets/ola-money-logo.png';
import paytmLogo from '@/assets/images/payment-method/wallets/paytm-logo.png';
import phonePeLogo from '@/assets/images/payment-method/wallets/phone-pe-logo.png';
import chekedIcon from '@/assets/images/payment-method/checked-icon.svg';

import './index.scss';


const wallets = [
  {
    name: 'PayTM',
    logo: paytmLogo,
    value: 'paytm',
  },
  {
    name: 'PhonePe',
    logo: phonePeLogo,
    value: 'phonepe',
  },
  {
    name: 'OlaMoney',
    logo: olaMoneyLogo,
    value: 'olamoney',
  },
  {
    name: 'Airtel',
    logo: airtelLogo,
    value: 'airtel',
  },
];

function Wallet({
  data,
  form,
  onBack,
  setData,
}) {
  const [walletData, setWalletData] = useState(wallets);
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(false);
    }, 1000);
  }, [setLoadingPage]);

  const handleSearchWalletName = (e) => {
    const { value } = e.target;

    if (value) {
      const newWallets = walletData.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));

      setWalletData(newWallets);
    } else {
      setWalletData(wallets);
    }
  };

  const handleOnBack = () => {
    form.resetFields(['wallet-name']);
    setData({
      ...data,
      paymentMethod: null,
      paymentMethodInfo: {}
    });
    onBack();
  };

  return (
    <div className={loadingPage ? 'text-center' : ''}>
      {loadingPage && <Spin />}
      {!loadingPage &&
        <>
          <HeadingPayMethod
            title='Wallet'
            onBack={handleOnBack}
            extra={
              <>
                <UserOutlined />
                <span>{data.phoneNumber}</span>
              </>
            }
          />

          <div className='rp-wallet'>
            <div className='rp-wallet__search'>
              <Input
                size="large"
                bordered={false}
                placeholder="Type a wallet name"
                onChange={handleSearchWalletName}
                prefix={<Icon icon='bx:search' style={{ fontSize: '18px' }} />}
              />
            </div>
            <div className='rp-wallet__text'>Popular Wallet</div>
            <div className='rp-wallet__list'>
              <Form.Item
                name='wallet-name'
                wrapperCol={{ span: 24 }}
              >
                <Radio.Group
                  className='w-100'
                >
                  {walletData.length > 0 && walletData.map(option => (
                    <Radio.Button
                      key={uniqueId()}
                      value={option.value}
                      className='rp-wallet__list-item__selector'
                    >
                      <div className='rp-wallet__list-item__label-container'>
                        <div className='rp-wallet__list-item__logo'>
                          <img
                            src={option.logo}
                            alt={option.name}
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div className='rp-wallet__list-item__name'>
                          {option.name}
                        </div>
                        <div className='rp-wallet__list-item__checked'>
                          <img
                            src={chekedIcon}
                            alt='Checked Icon'
                          />
                        </div>
                      </div>
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        </>
      }
    </div>
  );
}

Wallet.propTypes = {
  data: PropTypes.object,
  form: PropTypes.object,
  onBack: PropTypes.func,
  setData: PropTypes.func,
};

export default Wallet;
