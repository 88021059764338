import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'antd';
import {
  LeftOutlined,
} from '@ant-design/icons';

import './index.scss';

function Heading({
  title,
  onBack,
  extra,
}) {
  return (
    <div className="rp-paymethod-heading">
      <div className='rp-paymethod-heading__left'>
        <Button
          type='link'
          onClick={onBack}
        >
          <LeftOutlined />
          <span>{title}</span>
        </Button>
      </div>

      <div className='rp-paymethod-heading__right'>
        {!!extra && extra}
      </div>
    </div>
  );
}

Heading.propTypes = {
  extra: PropTypes.element,
  title: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default Heading;
