import React from 'react';
import PropTypes from 'prop-types';

import spImage from '@/assets/images/payment-pages/sp.svg';
import './index.scss';
import {
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';

function Deactivated({
  supportEmail,
  supportContact,
  merchantBussinessName,
}) {
  return (
    <div className='payment-pages-deactivated-container'>
      <div className="payment-pages-not-found__sp-image">
        <img
          src={spImage}
          alt="sp"
          width="100%"
          height="100%"
        />
      </div>
      <div className='payment-pages-deactivated__content'>
        <div className='payment-pages-deactivated__content-title'>
          <p>Oops, look like</p>
          <p>this payment page was deactivated</p>
        </div>
        <div className='payment-pages-deactivated__contact-merchant'>
          <p>For any queries, please contact <span className='fw-bold'>{merchantBussinessName}</span></p>
          <div className='payment-pages-deactivated__contact-info'>
            {!!supportContact &&
              <div className='payment-pages-deactivated__contact-info__item'>
                <PhoneOutlined />
                +{supportContact}
              </div>
            }
            {!!supportEmail &&
              <div className='payment-pages-deactivated__contact-info__item'>
                <MailOutlined />
                {supportEmail}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

Deactivated.propTypes = {
  supportEmail: PropTypes.string,
  supportContact: PropTypes.string,
  merchantBussinessName: PropTypes.string,
};

export default Deactivated;
