import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { STORAGE_VARIABLES } from '@/constants';

const PAY_SUCCEED = 'succeed';
const PAY_FAILED = 'failed';

function Finish() {
  const { search: searchParams } = useLocation();

  useEffect(() => {
    const paymentFinished = localStorage.getItem(STORAGE_VARIABLES.TRANS_CREATED);
    localStorage.removeItem(STORAGE_VARIABLES.TRANS_CREATED);
    if (paymentFinished === 'true' && searchParams ) {
      const urlSearchParams = new URLSearchParams(searchParams);
      const transactionId = urlSearchParams.get('transactionId');
      const status = urlSearchParams.get('status');
      const trasactionStatus = status === PAY_SUCCEED ? PAY_SUCCEED : PAY_FAILED;
      localStorage.setItem(`trans${transactionId}`, trasactionStatus);
      window.close();
    } else {
      window.location = '/not-found';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default Finish;
