/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import {
  Spin,
} from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import PaymentMethod from '@/components/PaymentMethod';
import {
  useBeforeRender
} from '@/utils';
import { paymentLinkService, paymentPageService, transactionService } from '@/services';
import Deactivated from '@/components/PaymentPages/Deactivated';
import OrderFormContainer from '@/containers/OrderContainer/form';
import {
  PAYMENT_PAGES_INPUT_TYPES,
  PAYMEN_LINK_STATUSES,
} from '@/constants';

function ViewContainer() {
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [paymentLinkData, setPaymentLinkData] = useState({});
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [payInformationForm, setPayInformationForm] = useState({
    email: null,
    amount: null,
    phoneNumber: null,
  });
  const [isInactived, setIsInactived] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [orderFormData, setOrderFormData] = useState({});
  const [orderFormFields, setOrderFormFields] = useState([
    {
      id: 'payLink',
      label: 'Amount',
      name: 'amount',
      amount: null,
      type: PAYMENT_PAGES_INPUT_TYPES.paymentItemType['fixed-amount'],
    },
    {
      label: 'Email',
      name: 'email',
      type: PAYMENT_PAGES_INPUT_TYPES.customerDetailFieldType.email,
    },
    {
      label: 'Phone Number',
      name: 'phoneNumber',
      type: PAYMENT_PAGES_INPUT_TYPES.customerDetailFieldType['phone-number'],
    },
  ]);
  const [currentMode, setCurrentMode] = useState(null);

  // Add inputName to fields and sort fields by position
  useEffect(() => {
    if (Object.keys(paymentLinkData).length > 0 && paymentLinkData.amount) {
      const fields = orderFormFields;
      const amountFieldIndex = fields.findIndex(field => field.id === 'payLink');
      fields[amountFieldIndex] = { ...fields[amountFieldIndex], amount: paymentLinkData.amount };
      setOrderFormFields(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLinkData]);

  const initData = async () => {
    const orderData = JSON.parse(sessionStorage.getItem(`pay${id}`));
    if (!!orderData && orderData.id) {
      setPayInformationForm({
        email: orderData.email,
        phoneNumber: orderData.contactNumber,
        amount: orderData.amount / 100,
      });
      setOrderId(orderData.id);
      setShowPaymentMethods(true);
    }

    let dataResponse = await paymentLinkService.getPaymentLinkDetails({ id });

    if (!dataResponse) {
      return;
    }
    dataResponse = {
      ...dataResponse,
      amount: dataResponse.amount / 100
    };
    setPaymentLinkData(dataResponse);
    setIsInactived(dataResponse.status !== PAYMEN_LINK_STATUSES.active);
    setCurrentMode(dataResponse.workMode);
    setTimeout(() => setPageLoading(false), 600);
  };

  const handleCreateOrder = (payload) => paymentPageService.createOrder(payload);

  const handleCreateTransaction = ({ paymentMethod, paymentInformation }) => {
    const payload = {
      orderId,
      paymentMethod,
      paymentInformation,
    };
    return transactionService.createTransactions(payload);
  };

  useBeforeRender(() => initData(), []);

  const handleBackToOrderForm = () => {
    sessionStorage.removeItem(`pay${id}`);
    setShowPaymentMethods(false);
  };

  return (
    <div className="rp__payment-links__view">
      <Spin
        spinning={pageLoading}
        className="loading-spiner"
        indicator={
          <Loading3QuartersOutlined spin style={{ fontSize: 36 }} />
        }
      />
      {isInactived && !pageLoading &&
        <Deactivated
          supportEmail={paymentLinkData?.supportEmail}
          supportContact={paymentLinkData?.supportContactNumber}
          merchantBussinessName={paymentLinkData?.merchant?.name}
        />
      }
      {!isInactived && !pageLoading &&
        <div className="rp__payment-links__view-container">
          <div className="rp__payment-links__view-slice" />
          <div className="rp__payment-links__view-main">
            {!showPaymentMethods &&
              <OrderFormContainer
                data={paymentLinkData}
                onFinish={handleCreateOrder}
                setShowPaymentMethods={setShowPaymentMethods}
                setPayInformationForm={setPayInformationForm}
                orderFormData={orderFormData}
                formFields={orderFormFields}
                setOrderFormData={setOrderFormData}
                setOrderId={setOrderId}
                refType="PaymentLink"
                currentMode={currentMode}
              />
            }
            {showPaymentMethods &&
              <PaymentMethod
                orderId={orderId}
                email={payInformationForm.email}
                amount={payInformationForm.amount}
                phoneNumber={payInformationForm.phoneNumber}
                merchantLogo={paymentLinkData.logo}
                merchantName={paymentLinkData.merchant?.name}
                onFinish={handleCreateTransaction}
                backToOrderForm={handleBackToOrderForm}
                currentMode={currentMode}
              />
            }
          </div>
        </div>
      }
    </div>
  );
}

export default ViewContainer;
