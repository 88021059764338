import React from 'react';
import PropTypes from 'prop-types';
import {
  Form
} from 'antd';
import { uniqueId } from 'lodash';

import RPInput from '@/components/RPInput';
import { PAYMENT_PAGES_INPUT_TYPES } from '@/constants';
import {
  validateEmail,
  validateAlphabets,
  validatePhoneNumber,
  validateAlphanumeric,
  hasSpecialCharacters,
} from '@/utils/validate';
import RPPhoneNumber from '@/components/RPPhoneNumber';
import RPTextArea from '@/components/RPTextArea';

function CustomerDetailField({
  fieldData,
  inputName,
}) {
  const {
    label,
    type: fieldType,
  } = fieldData;

  const renderStringSingleField = () => (
    <Form.Item
      key={uniqueId()}
      name={inputName}
      rules={[
        {
          required: true,
          message: `Please input ${label}`
        },
        () => ({
          validator(_, value) {
            if (value && hasSpecialCharacters(value)) {
              return Promise.reject(new Error(`${label} Invalid`));
            }
            return Promise.resolve();
          },
        }),
      ]}
      wrapperCol={{ span: 24 }}
    >
      <RPInput
        label={label}
        placeholder={label}
        required
        className="rp-paypage-detail-view-card__main__input"
      />
    </Form.Item>
  );
  switch (fieldType) {
    case PAYMENT_PAGES_INPUT_TYPES.customerDetailFieldType.email:
      return (
        <Form.Item
          key={uniqueId()}
          name={inputName}
          rules={[
            {
              required: true,
              message: `Please input ${label}`
            },
            () => ({
              validator(_, value) {
                if (value && !validateEmail(value)) {
                  return Promise.reject(new Error(`${label} Invalid`));
                }
                return Promise.resolve();
              },
            }),
          ]}
          wrapperCol={{ span: 24 }}
        >
          <RPInput
            required
            label={label}
            name={inputName}
            placeholder={label}
            className="rp-paypage-detail-view-card__main__input"
          />
        </Form.Item>
      );
    case PAYMENT_PAGES_INPUT_TYPES.customerDetailFieldType['phone-number']:
      return (
        <Form.Item
          key={uniqueId()}
          wrapperCol={{ span: 24 }}
          name={inputName}
          rules={[
            { required: true, message: `Please input your ${label}` },
            () => ({
              validator(_, value) {
                if (value && !validatePhoneNumber(value)) {
                  return Promise.reject(new Error(`${label} Invalid`));
                }

                return Promise.resolve();
              },
            }),
          ]}
          className="w-100"
        >
          <RPPhoneNumber
            name={inputName}
            specialLabel={label}
            placeholder={label}
            required
            inputProps={{
              name: inputName,
              required: true,
            }}
            className="rp-paypage-detail-view-card__main__input"
          />
        </Form.Item>
      );
    case PAYMENT_PAGES_INPUT_TYPES.customerDetailFieldType['single-line-text']:
      return renderStringSingleField();
    case PAYMENT_PAGES_INPUT_TYPES.customerDetailFieldType.number:
      return (
        <Form.Item
          key={uniqueId()}
          name={inputName}
          rules={[
            {
              required: true,
              message: `Please input ${label}`
            },
          ]}
          wrapperCol={{ span: 24 }}
        >
          <RPInput
            label={label}
            placeholder={label}
            required
            type="number"
            className="rp-paypage-detail-view-card__main__input"
          />
        </Form.Item>
      );
    case PAYMENT_PAGES_INPUT_TYPES.customerDetailFieldType.alphabets:
      return (
        <Form.Item
          key={uniqueId()}
          name={inputName}
          rules={[
            {
              required: true,
              message: `Please input ${label}`
            },
            () => ({
              validator(_, value) {
                if (value && !validateAlphabets(value)) {
                  return Promise.reject(new Error(`${label} Invalid. Please enter alphabets only`));
                }

                return Promise.resolve();
              },
            }),
          ]}
          wrapperCol={{ span: 24 }}
        >
          <RPInput
            label={label}
            placeholder={label}
            required
            type="string"
            className="rp-paypage-detail-view-card__main__input"
          />
        </Form.Item>
      );
    case PAYMENT_PAGES_INPUT_TYPES.customerDetailFieldType.alphanumeric:
      return (
        <Form.Item
          key={uniqueId()}
          name={inputName}
          rules={[
            {
              required: true,
              message: `Please input ${label}`
            },
            () => ({
              validator(_, value) {
                if (value && !validateAlphanumeric(value)) {
                  return Promise.reject(new Error(`${label} Invalid. Please enter alphanumeric only`));
                }

                return Promise.resolve();
              },
            }),
          ]}
          wrapperCol={{ span: 24 }}
        >
          <RPInput
            label={label}
            placeholder={label}
            required
            type="string"
            className="rp-paypage-detail-view-card__main__input"
          />
        </Form.Item>
      );
    case PAYMENT_PAGES_INPUT_TYPES.customerDetailFieldType['text-area']:
      return (
        <Form.Item
          key={uniqueId()}
          name={inputName}
          rules={[
            {
              required: true,
              message: `Please input ${label}`
            },
          ]}
          wrapperCol={{ span: 24 }}
        >
          <RPTextArea
            placeholder={label}
            label={label}
            rows={3}
            required
            className="rp-paypage-detail-view-card__main__input"
          />
        </Form.Item>
      );
    default:
      return renderStringSingleField();
  }
}

CustomerDetailField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  inputName: PropTypes.string.isRequired,
};

export default CustomerDetailField;
