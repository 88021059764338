import {
  PAYMENT_METHOD_OPTIONS,
  PAYMENT_METHODS_AVAILABLE,
} from './PaymentMethodOptions';

const ENV = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  EXPIRE_TIME_PAY_VIA_UPI: Number(process.env.REACT_APP_EXPIRE_TIME_PAY_VIA_UPI || 300000),
  EXPIRE_TIME_PAY_VIA_CARD: Number(process.env.REACT_APP_EXPIRE_TIME_PAY_VIA_CARD || 60000),
  EXPIRE_TIME_PAY_VIA_NETBANKING: Number(process.env.REACT_APP_EXPIRE_TIME_PAY_VIA_NETBANKING || 300000),
  HIDE_PAYMENT_METHODS: process.env.REACT_APP_HIDE_PAYMENT_METHODS?.split('|'),
};

const LoadingStatus = {
  idle: 'idle',
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected',
};

const ROUTES = {
  PAYMENT_PAGE: {
    VIEW: '/payment-page/:id/view',
    NOT_FOUND: '/payment-page/not-found',
  },
  TRANSACTION: {
    DETAIL: '/transaction/:id'
  },
  PAYMENT_BUTTON: {
    VIEW: '/payment-button/:id/view',
    NOT_FOUND: '/payment-button/not-found',
  },
  PAYMENTS: {
    FINISH: '/payments-finish',
    PERFORM_CHALLENGE_VERIFY: '/perform-verify',
    PAYMENT_PROCESSING: '/payment-processing',
  },
  PAYMENT_LINK: {
    VIEW: '/payment-link/:id/view',
    NOT_FOUND: '/payment-link/not-found',
  }
};

const PAYMENT_METHODS = {
  creditDebitCard: {
    value: 'credit_debit',
  },
  upi: {
    value: 'upi',
  },
  qrcode: {
    value: 'qrcode',
  },
  netbanking: {
    value: 'netbanking',
  },
  wallet: {
    value: 'WALLET',
  },
  prepaidCard: {
    value: 'PREPAID',
  },
};

const PAYMENT_PAGES_INPUT_TYPES = {
  paymentItemType: {
    'fixed-amount': 1,
    'customers-decide-amount': 2
  },
  customerDetailFieldType: {
    'email': 1,
    'single-line-text': 2,
    'number': 3,
    'phone-number': 4,
    'text-area': 5,
    'alphabets': 6,
    'alphanumeric': 7,
  }
};

const PAYMENT_PAGES_STATUSES = {
  active: 1,
  inactive: 2,
};

const DEFAULT_CURRENCY = 'INR';

const NOTIFCATION_MESSAGE_CONFIG = {
  placement: 'bottom',
  duration: 2.5,
  className: 'rp-message',
};

const TRANSACTION_STATUSES = {
  processing: 'PROCESSING',
  captured: 'CAPTURED',
  failed: 'FAILED',
};

const TRANSACTION_ACTIONS = {
  perform3DS: 'PERFORM_3DS',
  performChallenge: 'PERFORM_CHALLENGE',
};

const PAYMEN_BUTTON_STATUSES = {
  active: 1,
  inactive: 2,
};

const AMOUNT_LIMIT = {
  MIN: 0.01,
  MAX: 50000000,
};

const PAYMEN_LINK_STATUSES = {
  active: 1,
  paid: 2,
  cancelled: 3,
  expired: 4
};

// LocalStorage/SessionStorage Variables
const STORAGE_VARIABLES = {
  TRANS_CREATED: 'rdcp-payment-finished',
  PAY_PROCESSING: 'rdcp-payment-processing',
};

const RDCP_MODES = {
  LIVE_MODE: 'Live',
  TEST_MODE: 'Test',
};

export {
  ENV,
  ROUTES,
  RDCP_MODES,
  AMOUNT_LIMIT,
  LoadingStatus,
  PAYMENT_METHODS,
  DEFAULT_CURRENCY,
  STORAGE_VARIABLES,
  TRANSACTION_ACTIONS,
  TRANSACTION_STATUSES,
  PAYMENT_METHOD_OPTIONS,
  PAYMENT_PAGES_STATUSES,
  PAYMENT_PAGES_INPUT_TYPES,
  PAYMENT_METHODS_AVAILABLE,
  NOTIFCATION_MESSAGE_CONFIG,
  PAYMEN_BUTTON_STATUSES,
  PAYMEN_LINK_STATUSES,
};
