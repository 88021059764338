import React from 'react';
import PropTypes from 'prop-types';
import PaymentMethodCard from '@/components/PaymentMethod/PaymentCard/index';

function PaymentMethod({
  email,
  amount,
  orderId,
  onFinish,
  phoneNumber,
  merchantLogo,
  merchantName,
  backToOrderForm,
  embedMode = false,
  currentMode,
}) {
  return (
    <div className='rp-payment-methods-container'>
      <PaymentMethodCard
        email={email}
        amount={amount}
        orderId={orderId}
        phoneNumber={phoneNumber}
        onFinish={onFinish}
        merchantLogo={merchantLogo}
        merchantName={merchantName}
        embedMode={embedMode}
        backToOrderForm={backToOrderForm}
        currentMode={currentMode}
      />
    </div>
  );
}

PaymentMethod.propTypes = {
  embedMode: PropTypes.bool,
  merchantLogo: PropTypes.object,
  merchantName: PropTypes.string,
  email: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  onFinish: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  backToOrderForm: PropTypes.func.isRequired,
  currentMode: PropTypes.oneOf(['Live', 'Test']).isRequired,
};

export default PaymentMethod;
