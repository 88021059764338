import React from 'react';
import { WarningOutlined } from '@ant-design/icons';


const usePayModalConfirmConfig = ({ paymentChannel, config = {} }) => (
  {
    title: 'Cancel Payment?',
    content: 'Your payment is ongoing. Are you sure you want to cancel the payment?',
    icon: <WarningOutlined />,
    className: 'pay-button-close-confirm-container',
    okText: 'Yes, cancel',
    cancelText: 'No',
    keyboard: false,
    transitionName: '',
    maskTransitionName: '',
    ...config,
    onOk: () => {
      paymentChannel.postMessage({
        channel: 'rdcp-payment-button',
        event: 'close-rdcp-payment-form',
      });
    },
  }
);

export default usePayModalConfirmConfig;
