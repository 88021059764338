import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentChannel: {},
};

const slice = createSlice({
  name: 'paymentButtons',
  initialState,
  reducers: {
    clear(state) {
      state.paymentChannel = {};
    },
    setPaymentChannel: (state, { payload: { paymentChannel } }) => {
      state.paymentChannel = paymentChannel;
    }
  }
});

export const {
  setPaymentChannel
} = slice.actions;
export default slice.reducer;
