import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';
import { STORAGE_VARIABLES } from '@/constants';
import logoWhite from '@/assets/images/logo-white.svg';
import logoBlue from '@/assets/images/logo-blue.svg';
import './index.scss';

function Processing() {
  const [allowAccess, setAllowAccess] = useState(false);
  useEffect(() => {
    const paymentProcessing = localStorage.getItem(STORAGE_VARIABLES.PAY_PROCESSING);
    localStorage.removeItem(STORAGE_VARIABLES.PAY_PROCESSING);
    if (paymentProcessing) {
      setAllowAccess(true);
    } else {
      window.location = '/not-found';
    }
  }, [setAllowAccess]);

  const loadingIcon = (
    <Loading3QuartersOutlined
      style={{
        fontSize: 32,
      }}
      spin
    />
  );

  return (
    <div className="rdcp-payment-processing-container">
      {allowAccess &&
        <>
          <div className="rdcp-payment-processing-slice" />
          <div className="header">
            <div className="header__logo">
              <img
                src={logoWhite}
                alt="Radical Pay"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="content">
            <div className="card-container">
              <div className="card-body">
                <div className="title">Loading Bank Page</div>
                <div className="subtitle">Please wait while we redirect you to your bank page</div>
                <div className="loading-icon">
                  <Spin indicator={loadingIcon} />
                </div>
              </div>
              <div className="card-footer">
                <span>Secured by</span>
                <div className="footer-logo">
                  <img
                    src={logoBlue}
                    alt="Radical Pay"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <span>© {moment().format('YYYY')} RadicalPay. All right reserved.</span>
          </div>
        </>
      }
    </div>
  );
}

export default Processing;
