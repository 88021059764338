import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Form, Spin,
} from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons';

import {
  parse,
  cardTypes,
} from '@/utils';
import RPInput from '@/components/RPInput';
import RPDatePicker from '@/components/RPDatePicker';
import HeadingPayMethod from '@/components/PaymentMethod/Methods/Heading';
import jcbCard from '@/assets/images/payment-method/card-types/jcb.svg';
import jcbCardActive from '@/assets/images/payment-method/card-types/jcb-active.svg';
import visaCard from '@/assets/images/payment-method/card-types/visa.svg';
import visaCardActive from '@/assets/images/payment-method/card-types/visa-active.svg';
import amexCard from '@/assets/images/payment-method/card-types/amex.svg';
import amexCardActive from '@/assets/images/payment-method/card-types/amex-active.svg';
import mastercard from '@/assets/images/payment-method/card-types/mastercard.svg';
import mastercardActive from '@/assets/images/payment-method/card-types/mastercard-active.svg';
import dinersclubCard from '@/assets/images/payment-method/card-types/dinersclub.svg';
import dinersclubCardActive from '@/assets/images/payment-method/card-types/dinersclub-active.svg';
import { hasSpecialCharacters } from '@/utils/validate';
import CardInputMask from './CardInputMask';

import './index.scss';

function CreditDebitCard({
  data,
  form,
  onBack,
  setData,
  phoneNumber
}) {
  const [expirationMonth, setExpirationMonth] = useState();
  const [expirationYear, setExpirationYear] = useState();
  const [cardType, setCardType] = useState();
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(false);
    }, 1000);
  }, [setLoadingPage]);

  const handleOnChangeMonth = (date, dateString) => {
    setExpirationMonth(dateString);
    setData({
      ...data,
      paymentInformation: {
        ...data.paymentInformation,
        expiryDate: {
          ...data.paymentInformation.expiryDate,
          month: Number.parseInt(dateString, 10),
        },
      }
    });
  };

  const handleOnChangeYear = (date, dateString) => {
    setExpirationYear(dateString);
    setData({
      ...data,
      paymentInformation: {
        ...data.paymentInformation,
        expiryDate: {
          ...data.paymentInformation.expiryDate,
          year: Number.parseInt(date.format('YY'), 10),
        },
      }
    });
  };

  const handleOnChangeNameCard = (e) => {
    setData({
      ...data,
      paymentInformation: {
        ...data.paymentInformation,
        cardHolderName: e.target.value,
      }
    });
  };

  const handleOnChangeCVC = (e) => {
    setData({
      ...data,
      paymentInformation: {
        ...data.paymentInformation,
        cvv: e.target.value,
      }
    });
  };

  const handleChangeCardNumber = (e) => {
    const cardNumberUnmask = e.target.value.replace(/[^\d]/g, '');
    setCardType(cardTypes.getCardTypeByValue(e.target.value));

    setData({
      ...data,
      paymentInformation: {
        ...data.paymentInformation,
        number: cardNumberUnmask,
      }
    });
  };

  const isMasterCard = () => cardType?.type === 'mastercard';
  const isVisaCard = () => cardType?.type === 'visa';
  const isAmexCard = () => cardType?.type === 'amex';
  const isDinersclubCard = () => cardType?.type === 'dinersclub';
  const isJcbCard = () => cardType?.type === 'jcb';

  const disabledMonths = (current) => (
    expirationYear && expirationYear <= moment().year() && current.month() < moment().month()
  );

  const disabledYears = (current) => (current && current.year() < moment().year()) ||
    (expirationMonth && expirationMonth <= moment().month() && current.year() <= moment().year());

  const handleOnBack = () => {
    form.resetFields(['cardHolderName', 'number', 'month', 'year', 'cvv']);
    setData({
      ...data,
      paymentMethod: null,
      paymentInformation: {}
    });
    onBack();
  };

  const renderExtra = () => {
    if (!phoneNumber) {
      return null;
    }

    return (
      <>
        <UserOutlined />
        <span>{parse.phoneNumberInternational(phoneNumber)}</span>
      </>
    );
  };

  return (
    <div className={`rp-credit-debit-card${loadingPage ? ' text-center' : ''}`}>
      {loadingPage && <Spin />}
      {!loadingPage &&
        <>
          <HeadingPayMethod
            title="Credit / Debit Card"
            onBack={handleOnBack}
            extra={renderExtra()}
          />

          <div className='rp-credit-debit-card__cards'>
            <div className='rp-credit-debit-card__cards-item'>
              <img
                src={isMasterCard() ? mastercardActive : mastercard}
                alt='mastercard'
              />
            </div>
            <div className='rp-credit-debit-card__cards-item'>
              <img
                src={isVisaCard() ? visaCardActive : visaCard}
                alt='visa Card'
              />
            </div>
            <div className='rp-credit-debit-card__cards-item'>
              <img
                src={isAmexCard() ? amexCardActive : amexCard}
                alt='American Express'
              />
            </div>
            <div className='rp-credit-debit-card__cards-item'>
              <img
                src={isDinersclubCard() ? dinersclubCardActive : dinersclubCard}
                alt='Diners Club'
              />
            </div>
            <div className='rp-credit-debit-card__cards-item'>
              <img
                src={isJcbCard() ? jcbCardActive : jcbCard}
                alt='JCB'
              />
            </div>
          </div>

          <div className='rp-credit-debit-card__form'>
            <Form.Item
              name="cardHolderName"
              rules={[
                { required: true, message: 'Please Input Card Holder’s Name' },
                () => ({
                  validator(_, value) {
                    if (value && hasSpecialCharacters(value)) {
                      return Promise.reject(new Error('The Card Holder\'s Name format is invalid.'));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              wrapperCol={{ span: 24 }}
            >
              <RPInput
                className="rp-payment-card__body__input mb-0"
                placeholder="Card Holder’s Name"
                label="Card Holder’s Name"
                type="text"
                required
                onChange={handleOnChangeNameCard}
              />
            </Form.Item>

            <Form.Item
              name="number"
              rules={[
                {
                  required: true,
                  message: 'Please Input Card Number',
                },
              ]}
              wrapperCol={{ span: 24 }}
            >
              <CardInputMask
                required
                type="tel"
                maskChar={null}
                label='Card Number'
                placeholder='Card Number'
                mask='9999-9999-9999-9999'
                className="rp-payment-card__body__input mb-0"
                onChange={handleChangeCardNumber}
              />
            </Form.Item>

            <div className='rp-credit-debit-card__form__input-inline'>
              <Form.Item
                name="month"
                rules={[
                  { required: true, message: 'Please Input Expiration Month' },
                ]}
                wrapperCol={{ span: 24 }}
              >
                <RPDatePicker
                  className="rp-payment-card__body__input mb-0"
                  placeholder="Month"
                  label="Month"
                  required
                  picker="month"
                  mode="month"
                  format="MM"
                  suffixIcon={null}
                  allowClear={false}
                  style={{ textAlign: 'center' }}
                  disabledDate={disabledMonths}
                  onChange={handleOnChangeMonth}
                  inputReadOnly
                  popupClassName="month-picker"
                />
              </Form.Item>

              <Form.Item
                name="year"
                rules={[
                  { required: true, message: 'Please Input Expiration Year' }
                ]}
                wrapperCol={{ span: 24 }}
              >
                <RPDatePicker
                  className="rp-payment-card__body__input mb-0"
                  placeholder="Year"
                  label="Year"
                  required
                  picker="year"
                  mode="year"
                  format="YYYY"
                  suffixIcon={null}
                  allowClear={false}
                  disabledDate={disabledYears}
                  onChange={handleOnChangeYear}
                  inputReadOnly
                />
              </Form.Item>

              <Form.Item
                name="cvv"
                rules={[
                  { required: true, message: 'Please Input CVC' },
                ]}
                wrapperCol={{ span: 24 }}
              >
                <RPInput
                  required
                  className="rp-payment-card__body__input mb-0"
                  placeholder="CVV"
                  label="CVV"
                  type="password"
                  maxLength={3}
                  pattern="\d{3,4}"
                  onChange={handleOnChangeCVC}
                />
              </Form.Item>
            </div>
          </div>
        </>
      }
    </div>
  );
}

CreditDebitCard.propTypes = {
  data: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
};

export default CreditDebitCard;
