import React from 'react';

import DetailContainer from '@/containers/Transaction/ViewContainer';
import './detail.scss';

function Detail() {

  return (
    <DetailContainer />
  );
}

export default Detail;
