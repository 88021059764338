import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Form,
  Radio,
  Spin,
} from 'antd';
import { uniqueId } from 'lodash';
import { Icon } from '@iconify/react';
import {
  UserOutlined,
} from '@ant-design/icons';

import RPInput from '@/components/RPInput';
import HeadingPayMethod from '@/components/PaymentMethod/Methods/Heading';
import chekedIcon from '@/assets/images/payment-method/checked-icon.svg';
import amazonLogo from '@/assets/images/payment-method/prepaid-cards/amazon.png';
import appleLogo from '@/assets/images/payment-method/prepaid-cards/apple.png';
import gPayLogo from '@/assets/images/payment-method/prepaid-cards/g-pay.png';
import tescoLogo from '@/assets/images/payment-method/prepaid-cards/tesco.png';

import './index.scss';

const prepaidCards = [
  { name: 'Tesco', logo: tescoLogo, value: 'tesco' },
  { name: 'Apple', logo: appleLogo, value: 'apple' },
  { name: 'Amazon', logo: amazonLogo, value: 'amazon' },
  { name: 'Google Pay', logo: gPayLogo, value: 'g-pay' },
];

function PrepaidCard({
  data,
  form,
  onBack,
  setData,
}) {
  const [prepaidCardsData, setPrepaidCardsData] = useState(prepaidCards);
  const [cardSelected, setCardSelected] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(false);
    }, 1000);
  }, [setLoadingPage]);

  const handleSearchWalletName = (e) => {
    const { value } = e.target;

    if (value) {
      const newPrepaidCards = prepaidCardsData.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));

      setPrepaidCardsData(newPrepaidCards);
    } else {
      setPrepaidCardsData(prepaidCards);
    }
  };

  const handleOnSelectCard = (e) => {
    const { value } = e.target;
    setCardSelected(value);
  };

  const renderInputCardNo = () => {
    if (!cardSelected) {
      return null;
    }

    const prepaidCard = prepaidCards.find(item => item.value === cardSelected);

    return (
      <div className='rp-prepaid-card__input-card-no'>
        <div className='rp-prepaid-card__input-card-no__company-card'>
          <div className='rp-prepaid-card__input-card-no__company-card__logo'>
            <img
              src={prepaidCard.logo}
              alt={prepaidCard.name}
            />
          </div>
          <div className='rp-prepaid-card__input-card-no__company-card__name'>{prepaidCard.name}</div>
        </div>
        <div className='rp-prepaid-card__input-card-no__card-no'>
          <Form.Item
            name='card-number'
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: 'Please Input Card No.' }]}
          >
            <RPInput
              label='Card No.'
              placeholder='Card No.'
              required
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const handleOnBack = () => {
    form.resetFields(['card-name', 'card-number']);
    setData({
      ...data,
      paymentMethod: null,
      paymentMethodInfo: {}
    });
    onBack();
  };

  return (
    <div className={loadingPage ? 'text-center' : ''}>
      {loadingPage && <Spin />}
      {!loadingPage &&
        <>
          <HeadingPayMethod
            title='Gift Card/ Prepaid Card'
            onBack={handleOnBack}
            extra={
              <>
                <UserOutlined />
                <span>{data.phoneNumber}</span>
              </>
            }
          />
          {!cardSelected &&
            <div className='rp-prepaid-card'>
              <div className='rp-prepaid-card__search'>
                <Input
                  size="large"
                  bordered={false}
                  placeholder="Select Card Company"
                  onChange={handleSearchWalletName}
                  prefix={<Icon icon='bx:search' style={{ fontSize: '18px' }} />}
                />
              </div>
              <div className='rp-prepaid-card__text'>Popular  Card</div>
              <div className='rp-prepaid-card__list'>
                <Form.Item
                  name='card-name'
                  wrapperCol={{ span: 24 }}
                >
                  <Radio.Group
                    className='w-100'
                    onChange={handleOnSelectCard}
                  >
                    {prepaidCardsData.length > 0 && prepaidCardsData.map(option => (
                      <Radio.Button
                        key={uniqueId()}
                        value={option.value}
                        className='rp-prepaid-card__list-item__selector'
                      >
                        <div className='rp-prepaid-card__list-item__label-container'>
                          <div className='rp-prepaid-card__list-item__logo'>
                            <img
                              src={option.logo}
                              alt={option.name}
                              width="100%"
                              height="100%"
                            />
                          </div>
                          <div className='rp-prepaid-card__list-item__name'>
                            {option.name}
                          </div>
                          <div className='rp-prepaid-card__list-item__checked'>
                            <img
                              src={chekedIcon}
                              alt='Checked Icon'
                            />
                          </div>
                        </div>
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          }

          {cardSelected && renderInputCardNo()}
        </>
      }
    </div>
  );
}

PrepaidCard.propTypes = {
  data: PropTypes.object,
  form: PropTypes.object,
  onBack: PropTypes.func,
  setData: PropTypes.func,
};

export default PrepaidCard;
