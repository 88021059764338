import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import parsePhoneNumber from 'libphonenumber-js';

export function camelize(obj) {
  return camelcaseKeys(obj, { deep: true });
}

export function snakelize(obj) {
  return snakecaseKeys(obj, { deep: true });
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function phoneNumberInternational(phoneNumber) {
  const parsedphoneNumber = parsePhoneNumber(`+${phoneNumber}`);
  return parsedphoneNumber.formatInternational();
}
