/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import './index.scss';

function HighlightTestMode() {
  return (
    <div className="highlight-test-mode-container">
      <div className="horizontal-line" />
      <div className="content">
        <div className="trapezoid">
          you're in test mode
        </div>
      </div>
    </div>
  );
}

export default HighlightTestMode;
