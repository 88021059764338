import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Radio,
} from 'antd';

import {
  validateEmail,
  validatePhoneNumber
} from '@/utils/validate';
import {
  ENV,
  PAYMENT_METHOD_OPTIONS
} from '@/constants';
import RPInput from '@/components/RPInput';
import RPPhoneNumber from '@/components/RPPhoneNumber';

function PayContent({
  data,
  email,
  setData,
  phoneNumber,
  setSelectPayMethod,
}) {
  const paymentMethodOptions = PAYMENT_METHOD_OPTIONS.filter(item => !ENV.HIDE_PAYMENT_METHODS.includes(item.value));
  const [enteredPhoneAndEmail, setEnteredPhoneAndEmail] = useState(() => email && phoneNumber);

  const handleOnChangePaymentMethod = (event) => {
    const { value } = event.target;

    setSelectPayMethod(value);
    setData({
      ...data,
      paymentMethod: value,
    });
  };

  const handleOnChangePhoneNumber = (phone) => {
    setData({
      ...data,
      phoneNumber: phone,
    });

    const isValidPhoneAndEmail = !!email && !!validateEmail(email) && !!phone && !!validatePhoneNumber(phone);

    setEnteredPhoneAndEmail(isValidPhoneAndEmail);
  };

  const handleOnChangeEmail = (e) => {
    const { value } = e.target;

    setData({
      ...data,
      email: value,
    });

    const isValidPhoneAndEmail = !!value &&
      !!validateEmail(value) &&
      !!phoneNumber &&
      !!validatePhoneNumber(phoneNumber);

    setEnteredPhoneAndEmail(isValidPhoneAndEmail);
  };

  return (
    <div className="rp-payment-card-wrapper">
      <div className="rp-payment-card__body__input__phone">
        <Form.Item
          wrapperCol={{ span: 24 }}
          className="w-100"
        >
          <RPPhoneNumber
            className="rp-payment-card__body__input --custom mb-0"
            specialLabel="Phone No."
            placeholder="Phone No."
            required
            inputProps={{
              name: 'phoneNumber',
              required: true,
            }}
            onChange={handleOnChangePhoneNumber}
            disabled
            value={phoneNumber}
          />
        </Form.Item>
      </div>
      <div className="rp-payment-card__body__input__email">
        <Form.Item
          wrapperCol={{ span: 24 }}
        >
          <RPInput
            name="email"
            label="Email"
            placeholder="Email"
            required
            onChange={handleOnChangeEmail}
            disabled
            value={email}
          />
        </Form.Item>
      </div>

      <p className='rp-payment-card__body__choose-pay-method-text'>Choose Payment Method</p>

      {/* Payment Methods */}
      <div className='rp-payment-methods'>
        {paymentMethodOptions.length > 0 &&
          <Radio.Group
            disabled={!enteredPhoneAndEmail}
            className='w-100'
            onChange={handleOnChangePaymentMethod}
          >
            {paymentMethodOptions.map(method =>
              <Radio.Button
                key={method.value}
                value={method.value}
              >
                <Row
                  className='rp-payment-methods__item-option'
                >
                  <Col className='rp-payment-methods__item-option__describe'>
                    <div className='rp-payment-methods__item-option__icon'>
                      <img
                        src={method.icon}
                        alt={method.name}
                      />
                    </div>
                    <div className='rp-payment-methods__item-option__icon --active'>
                      <img
                        src={method.iconActive}
                        alt={method.name}
                      />
                    </div>
                    <div className='rp-payment-methods__item-option__right-content'>
                      <div className='rp-payment-methods__item-option__title'>
                        {method.name}
                      </div>
                      <div className='rp-payment-methods__item-option__desc'>
                        {method.description}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Radio.Button>
            )}
          </Radio.Group>
        }
      </div >
    </div >
  );
}

PayContent.propTypes = {
  data: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setSelectPayMethod: PropTypes.func.isRequired,
};

export default PayContent;
