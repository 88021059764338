import React from 'react';
import PropTypes from 'prop-types';
import ReactInputMask from 'react-input-mask';

import RPInput from '@/components/RPInput';

function CardInputMask(props) {
  return (
    <ReactInputMask {...props}>
      {(inputProps) =>
        <RPInput {...inputProps} />
      }
    </ReactInputMask>
  );
}

CardInputMask.propTypes = {
  mask: PropTypes.string,
  inputRef: PropTypes.func,
  maskChar: PropTypes.string,
  formatChars: PropTypes.object,
  alwaysShowMask: PropTypes.bool,
};

export default CardInputMask;
