import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Radio,
  Spin,
} from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons';
import { generatePath } from 'react-router-dom';

import { ENV, PAYMENT_METHODS, ROUTES, STORAGE_VARIABLES, TRANSACTION_STATUSES } from '@/constants';
import { validateUpiId } from '@/utils/validate';
import RPInput from '@/components/RPInput';
import HeadingPayMethod from '@/components/PaymentMethod/Methods/Heading';
import { parse, windowUtil } from '@/utils';
import { transactionService } from '@/services';

import './index.scss';

const PAY_SUCCEED = 'succeed';
const PAY_FAILED = 'failed';

function UPIQRCode({
  data,
  form,
  onBack,
  setData,
  phoneNumber,
  toggleRunPay,
  setToggleRunPay,
  embedMode = false,
  handlePaymentUnknownError,
  handleCreateTransaction
}) {
  // handle variable

  const [value, setValue] = useState('upi');
  const [loadingPage, setLoadingPage] = useState(true);
  const [externalWindow, setExternalWindow] = useState(null);
  const [currentTransactionId, setCurrentTransactionId] = useState(null);
  const [upiPayLink, setUpiPayLink] = useState(null);
  const [processFinalStep, setProcessFinalStep] = useState(false);

  // handle function

  const onPay = async () => {
    setToggleRunPay(false);
    localStorage.setItem(STORAGE_VARIABLES.PAY_PROCESSING, true);
    const newWindow = windowUtil.openExternalWindow(ROUTES.PAYMENTS.PAYMENT_PROCESSING);
    setExternalWindow(newWindow);

    const { upiId } = data.paymentMethodInfo || {};
    // call api create transaction => return url
    const { paymentMethod } = data;
    const { success, data: dataRes } = await handleCreateTransaction({ paymentMethod, paymentInformation: { upiId } });
    if (success) {
      setCurrentTransactionId(dataRes.id);
      sessionStorage.removeItem(`pay${dataRes.order.refId}`);
      const { nextAction } = dataRes;
      const { action, paymentInfo } = nextAction;
      if (action === 'PAYMENT_INFO') {
        const { paymentUrl } = paymentInfo;
        setUpiPayLink(paymentUrl);
      }

      return;
    };
    // handler error
    setExternalWindow(null);
    handlePaymentUnknownError();
  };

  const onChange = (e) => {
    const payMethod = e.target.value;

    setValue(payMethod);
    setData({
      ...data,
      paymentMethod: PAYMENT_METHODS[payMethod]?.value,
    });

    if (payMethod === 'qrcode') {
      setData({
        ...data,
        paymentMethodInfo: {},
      });
      form.resetFields(['upiId']);
    }
  };

  const handleOnChangeUpiID = (e) => {
    setData({
      ...data,
      paymentMethodInfo: {
        upiId: e.target.value,
      }
    });
  };

  const handleOnBack = () => {
    form.resetFields(['upiId']);
    setData({
      ...data,
      paymentMethod: null,
      paymentMethodInfo: {}
    });
    onBack();
  };

  const renderExtra = () => {
    if (!phoneNumber) {
      return null;
    }

    return (
      <>
        <UserOutlined />
        <span>{parse.phoneNumberInternational(phoneNumber)}</span>
      </>
    );
  };

  // handle useEffect

  useEffect(() => {
    form.resetFields(['upiId']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toggleRunPay) {
      console.log('Paying...');
      onPay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleRunPay]);

  useEffect(() => {
    if (externalWindow) {
      let timeout;
      if (!externalWindow.closed) {
        console.log(`Window opened. Closing after ${ENV.EXPIRE_TIME_PAY_VIA_UPI / 60 / 1000}min`);
        timeout = setTimeout((newWindow) => {
          console.log('running timeout open window');
          newWindow.close();
          setProcessFinalStep(true);
        }, ENV.EXPIRE_TIME_PAY_VIA_UPI, externalWindow);
      }

      const timer = setInterval(() => {
        if (externalWindow.closed) {
          console.log('Window closed');
          clearTimeout(timeout);
          clearInterval(timer);
          setProcessFinalStep(true);
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalWindow]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(false);
    }, 1000);
  }, [setLoadingPage]);

  useEffect(() => {
    if (upiPayLink && externalWindow) {
      console.log('Start Pay via UPI link');
      localStorage.setItem(STORAGE_VARIABLES.TRANS_CREATED, true);
      externalWindow.location.href = upiPayLink;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upiPayLink]);

  useEffect(() => {
    const handlePaymentFinalStep = async () => {
      localStorage.removeItem(STORAGE_VARIABLES.PAY_PROCESSING);
      localStorage.removeItem(STORAGE_VARIABLES.TRANS_CREATED);
      if (!currentTransactionId) {
        setExternalWindow(null);
        handlePaymentUnknownError();
      }

      if (embedMode) {
        localStorage.setItem('rp-payment-btn-transaction', 'created');
      }
      const paymentResult = localStorage.getItem(`trans${currentTransactionId}`);
      // Handle Payment Succeed/Failed from BE response
      if (paymentResult === PAY_SUCCEED || paymentResult === PAY_FAILED) {
        console.log(`Pay ${paymentResult}`);
        window.location = generatePath(ROUTES.TRANSACTION.DETAIL, { id: currentTransactionId });
      } else {  // Handle timeout of payment reached
        console.log('Pay time out OR user close window');
        const {
          success,
          data: responseData
        } = await transactionService.getTransaction(currentTransactionId);
        const canRedirectToTransactionDetail = success &&
          (
            responseData?.status === TRANSACTION_STATUSES.captured ||
            responseData?.status === TRANSACTION_STATUSES.failed
          );
        if (canRedirectToTransactionDetail) {
          window.location = generatePath(ROUTES.TRANSACTION.DETAIL, { id: currentTransactionId });
        } else {
          localStorage.removeItem('rp-payment-btn-transaction');
          handlePaymentUnknownError();
        }
      }
    };

    if (processFinalStep) {
      handlePaymentFinalStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processFinalStep]);

  return (
    <div className={`rp-upi-qr-code${loadingPage ? ' text-center' : ''}`}>
      {loadingPage && <Spin />}
      {!loadingPage &&
        <>
          <HeadingPayMethod
            title='UPI'
            onBack={handleOnBack}
            extra={renderExtra()}
          />

          <Radio.Group
            onChange={onChange}
            defaultValue='upi'
            name='upi-qrcode'
            className='rp-upi-qr-code__options'
          >
            <div className='rp-upi-qr-code__options__item'>
              <Radio value='upi'>PAY USING UPI ID</Radio>
              <div className={`radio-upi-id${value === 'upi' ? ' --active' : ''}`}>
                <Form.Item
                  rules={[
                    { required: value !== 'qrcode', message: 'Please enter UPI ID' },
                    () => ({
                      validator(_, currentValue) {
                        if (currentValue && !validateUpiId(currentValue)) {
                          return Promise.reject(new Error('UPI ID invalid'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  name='upiId'
                  wrapperCol={{ span: 24 }}
                >
                  <RPInput
                    name={['uip', 'upiId']}
                    label='UPI ID'
                    placeholder='code@upi'
                    required
                    onChange={handleOnChangeUpiID}
                  />
                </Form.Item>
              </div>
            </div>
            {/* <div className='divide' />
            <div className='rp-upi-qr-code__options__item'>
              <Radio value='qrcode'>PAY USING QR CODE</Radio>
              <div className={`radio-qr-code${value === 'qrcode' ? ' --active' : ''}`}>
                <img
                  src={qrcode}
                  alt='qrcode'
                  width='100%'
                  height='100%'
                />
              </div>
            </div> */}
          </Radio.Group>
        </>
      }
    </div>
  );
}

UPIQRCode.propTypes = {
  data: PropTypes.object,
  form: PropTypes.object,
  onBack: PropTypes.func,
  setData: PropTypes.func,
  phoneNumber: PropTypes.string.isRequired,
  toggleRunPay: PropTypes.bool,
  setToggleRunPay: PropTypes.func,
  handlePaymentUnknownError: PropTypes.func.isRequired,
  handleCreateTransaction: PropTypes.func.isRequired,
  embedMode: PropTypes.bool,
};

export default UPIQRCode;
