import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { transactionService } from '@/services';
import { RDCP_MODES, TRANSACTION_STATUSES } from '@/constants';
import PaymentCompletedCard from '@/components/PaymentMethod/PaymentCompletedCard/index';
import FailedTransaction from '@/components/PaymentMethod/PaymentCompletedCard/FailedTransaction';
import HighlightTestMode from '@/components/shared/HighlightTestMode';
import { getPaymentPagesDetails } from '@/services/paymentPageService';
import { getPaymentButtonsDetails } from '@/services/paymentButtonService';
import { getPaymentLinkDetails } from '@/services/paymentLinkService';

const PAY_SUCCEED = 'succeed';

function ViewContainer() {
  const params = useParams();
  const [failed, setFailed] = useState(true);
  const [transactionData, setTransactionData] = useState({});
  const [paymentRefData, setPaymentRefData] = useState();
  const [loading, setLoading] = useState(true);
  const [embedMode, setEmbedMode] = useState(false);
  const [payRefType, setPayRefType] = useState('');
  const [currentMode, setCurrentMode] = useState(null);

  const execRefDetail = async (getRefDetail, refId) => {
    const dataRef = await getRefDetail({ id: refId });
    setPaymentRefData(dataRef);
  };

  const fetchRefTransaction = (data) => {
    const { refId, refType, workMode } = data.order;
    setCurrentMode(workMode);
    setPayRefType(refType);
    let getRefDetail;
    switch (refType) {
      case 'PaymentPage':
        getRefDetail = getPaymentPagesDetails;
        break;
      case 'PaymentButton':
        getRefDetail = getPaymentButtonsDetails;
        break;
      case 'PaymentLink':
        getRefDetail = getPaymentLinkDetails;
        break;
      default:
        break;
    }
    if (typeof getRefDetail === 'function') {
      execRefDetail(getRefDetail, refId);
      setTransactionData(data);
    }
  };

  const exceSuccess = (data) => {
    fetchRefTransaction(data);
    const paymentResult = localStorage.getItem(`trans${params.id}`);
    if (paymentResult === PAY_SUCCEED || data.status === TRANSACTION_STATUSES.captured) {
      setFailed(false);
    }
    // Remove transaction result (succeed || failed saved in localStorage)
    if (data.status === TRANSACTION_STATUSES.captured || data.status === TRANSACTION_STATUSES.failed) {
      localStorage.removeItem(`trans${params.id}`);
    }
    setLoading(false);
  };

  useEffect(() => {


    const fetchData = async () => {
      if (params.id) {
        const { success, data } = await transactionService.getTransaction(params.id);
        if (success) {
          exceSuccess(data);
        } else {
          window.location.href = '/404';
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    // Use for embed payment button code
    const payBtnTransactionCreated = localStorage.getItem('rp-payment-btn-transaction') === 'created';
    if (payBtnTransactionCreated) {
      document.body.classList.toggle('bg-transparent', true);
      setEmbedMode(true);
    }
    localStorage.removeItem('rp-payment-btn-transaction');
  }, []);

  return (
    <div className="rp__payment-transactions__detail">
      <Spin
        spinning={loading}
        className="loading-spiner"
        indicator={
          <Loading3QuartersOutlined spin style={{ fontSize: 36 }} />
        }
      />
      <div className="rp__payment-transactions__detail-container">
        <div className="rp__payment-transactions__detail-slice" />
        <div className="rp__payment-transactions__detail-main">
          <div className='rp-payment-methods-container position-relative'>
            {currentMode === RDCP_MODES.TEST_MODE && <HighlightTestMode />}
            {!loading && !failed && paymentRefData &&
              <PaymentCompletedCard
                paymentRef={paymentRefData}
                transactionData={transactionData}
                embedMode={embedMode}
              />
            }
            {!loading && failed && paymentRefData &&
              <FailedTransaction
                paymentRef={paymentRefData}
                transactionData={transactionData}
                refType={payRefType}
                embedMode={embedMode}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewContainer;
