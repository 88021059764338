import { configureStore, combineReducers } from '@reduxjs/toolkit';
import paymentButtonReducer from '@/store/paymentButton/paymentButtonSlice';

const reducer = combineReducers({
  paymentButtons: paymentButtonReducer,
});

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          'payload.paymentChannel',
        ],
        ignoredPaths: [
          'paymentButtons.paymentChannel',
        ],
      },
    }),
  reducer,
});

export default store;
