import React from 'react';
import { Switch } from 'react-router-dom';
import { ROUTES } from '@/constants';
import GuestRoute from '@/routes/GuestRoute';
import PaymentPageView from '@/pages/PaymentPage/view';
import TransactionPage from '@/pages/TransactionPage/detail';
import PaymentButtonView from '@/pages/PaymentButton/view';
import NotFoundPage from '@/pages/NotFoundPage';
import PaymentPageNotFound from '@/pages/PaymentPage/NotFound';
import PerformChallenge from '@/pages/Payment/PerformChallenge';
import PaymentFinish from '@/pages/Payment/Finish';
import PaymentLinkView from '@/pages/PaymentLink/view';
import PaymentProcessing from '@/pages/Payment/Processing';

function Routes() {
  return (
    <Switch>
      <GuestRoute
        exact
        path={ROUTES.PAYMENT_PAGE.NOT_FOUND}
        component={PaymentPageNotFound}
      />
      <GuestRoute
        exact
        path={ROUTES.PAYMENT_PAGE.VIEW}
        component={PaymentPageView}
      />
      <GuestRoute
        exact
        path={ROUTES.TRANSACTION.DETAIL}
        component={TransactionPage}
      />
      <GuestRoute
        exact
        path={ROUTES.PAYMENT_BUTTON.VIEW}
        component={PaymentButtonView}
      />
      <GuestRoute
        exact
        path={ROUTES.PAYMENT_LINK.VIEW}
        component={PaymentLinkView}
      />
      <GuestRoute
        exact
        path={ROUTES.PAYMENTS.PERFORM_CHALLENGE_VERIFY}
        component={PerformChallenge}
      />
      <GuestRoute
        exact
        path={ROUTES.PAYMENTS.FINISH}
        component={PaymentFinish}
      />
      <GuestRoute
        exact
        path={ROUTES.PAYMENTS.PAYMENT_PROCESSING}
        component={PaymentProcessing}
      />
      <GuestRoute
        path='*'
        component={NotFoundPage}
      />
    </Switch>
  );
}

export default Routes;
