import { useState, useEffect } from 'react';

export * as errorUtil from './errorUtil';
export * as loadingUtil from './loadingUtil';
export * as cardTypes from './cardTypes';
export * as formatter from './formatter';
export * as parse from './parse';
export * as messageNotifyUtil from './messageNotifyUtil';
export * as paymentUtil from './paymentUtil';
export * as windowUtil from './window';

export const useBeforeRender = (callback, deps) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => setIsRun(false), deps);
};
