import { DEFAULT_CURRENCY } from '@/constants';
import moment from 'moment';

export const formatCurrency = (number, currency = DEFAULT_CURRENCY) =>
  new Intl.NumberFormat('en-US', { style: 'currency', minimumFractionDigits: 2, currency })
    .format(Number(number).toFixed(2));

export const currencySymbol = (currency) =>
  new Intl.NumberFormat('en', { style: 'currency', currency })
    .formatToParts('1')
    .find(part => part.type === 'currency')
    .value;

export const parseDateTime = (date) => moment(new Date(date)).format('YYYY/MM/DD, hh:mma');
