import React from 'react';

import ViewContainer from '@/containers/PaymentButton/ViewContainer';

import './view.scss';

function View() {

  return (
    <ViewContainer />
  );
}

export default View;
