const configPopupCenter = ({ w, h }) => {
  const left = (window.screen.width / 2) - (w / 2);
  const top = (window.screen.height / 2) - (h / 2);

  // eslint-disable-next-line max-len
  return (`toolbar=no,location=no,directories=no,status=no,menubar=no,resizable=no,copyhistory=no,width=${w},height=${h},top=${top},left=${left}`);
};

export const openExternalWindow = (url) => {
  const windowFeatures = configPopupCenter({ w: 775, h: 620 });
  return window.open(url, '', windowFeatures);
};
