/* eslint-disable max-len */
import {
  parsePhoneNumberFromString
} from 'libphonenumber-js/max';

export const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export const validatePhoneNumber = phoneNumber => {
  const phone = parsePhoneNumberFromString(`+${phoneNumber}`);
  return phone && phone.isValid();
};

export const validateAlphabets = (email) => String(email)
  .toLowerCase()
  .match(
    /^[A-Za-z\s]+$/
  );

export const validateAlphanumeric = (email) => String(email)
  .toLowerCase()
  .match(
    /^[a-zA-Z0-9\s]+$/
  );

export const validateUpiId = upiId => String(upiId).match(/^\w.+@\w+$/);

export const hasSpecialCharacters = (string) => /[^\w\s]/gi.test(string);
