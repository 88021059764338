/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Modal,
  Button,
} from 'antd';
import {
  CloseOutlined,
} from '@ant-design/icons';
import { uniqueId } from 'lodash';
import { useSelector } from 'react-redux';

import RPButton from '@/components/RPButton';
import HighlightTestMode from '@/components/shared/HighlightTestMode';
import { PAYMENT_PAGES_INPUT_TYPES, RDCP_MODES } from '@/constants';
import logoBlueIcon from '@/assets/images/logo-blue.svg';
import { usePayModalConfirmConfig } from '@/hooks';
import { paymentUtil } from '@/utils';
import { selectPaymentButtons } from '@/store/paymentButton/paymentButtonSelector';
import CustomerDetailField from './CustomerDetailField';
import PaymentItemField from './PaymentItemField';

import './index.scss';

function OrderFormContainer({
  data,
  refType,
  onFinish,
  formFields,
  setOrderId,
  orderFormData,
  setOrderFormData,
  setShowPaymentMethods,
  setPayInformationForm,
  showCloseBtnX = false,
  currentMode,
}) {
  const [checkoutId, setCheckoutId] = useState('');
  const [loading, setLoading] = useState(false);
  const [formInstance] = Form.useForm();
  const defaultTAC = 'You agree to share information entered on this page with sachin (owner of this page) and Razorpay, adhering to applicable laws.';
  const {
    logo,
    title,
    merchant,
    description,
    supportEmail,
    termAndConditions,
    supportContactNumber,
  } = data;
  const { paymentChannel } = useSelector(selectPaymentButtons);
  const payModalConfirmConfig = usePayModalConfirmConfig({ paymentChannel });

  useEffect(() => {
    if (checkoutId === '') {
      setCheckoutId(uniqueId(`ORD${Date.now()}`));
    }
  }, [checkoutId]);

  const renderFieldByType = (field) => {
    const {
      id,
      amount,
      name: inputName,
      type: fieldType,
    } = field;

    if (id) {
      if (fieldType === PAYMENT_PAGES_INPUT_TYPES.paymentItemType['fixed-amount']) {
        formInstance.setFieldsValue({
          [inputName]: amount,
        });
      }

      return (
        <PaymentItemField
          key={uniqueId()}
          fieldData={field}
          inputName={inputName}
        />
      );
    }

    return (
      <CustomerDetailField
        key={uniqueId()}
        fieldData={field}
        inputName={inputName}
      />
    );
  };

  const handleOnFinish = async (values) => {
    setLoading(true);
    setOrderFormData(values);

    const payload = paymentUtil.parseCheckoutOrder({
      values,
      refType,
      checkoutId,
      refId: data.id,
      orderInformationFields: formFields,
    });

    const { success, data: dataResponse } = await onFinish(payload);

    if (success) {
      setPayInformationForm({
        amount: dataResponse.amount / 100.0,
        email: values.email,
        phoneNumber: values.phoneNumber,
      });
      // Hacky to prevent raise warn Can't perform a React state update on an unmounted component
      setTimeout(() => {
        setOrderId(dataResponse.id);
        setShowPaymentMethods(true);

        const payloadLocalStorage = {
          id: dataResponse.id,
          amount: dataResponse.amount,
          email: dataResponse.email,
          contactNumber: dataResponse.contactNumber,
        };
        if (refType === 'PaymentLink' || refType === 'PaymentPage') {
          sessionStorage.setItem(`pay${data.id}`, JSON.stringify(payloadLocalStorage));
        }
      }, 100);
    }
    setLoading(false);
  };

  const handleOnFinishFail = () => {
    setLoading(false);
  };

  return (
    <Form
      scrollToFirstError
      form={formInstance}
      onFinish={handleOnFinish}
      onFinishFailed={handleOnFinishFail}
      initialValues={orderFormData}
    >
      <div className='rp-paybutton-detail-view-card position-relative'>
        {currentMode === RDCP_MODES.TEST_MODE && <HighlightTestMode />}
        <div className='rp-paybutton-detail-view-card__wrapper'>
          <div className='rp-paybutton-detail-view-card__header__actions'>
            {showCloseBtnX &&
              <Button
                type='link'
                onClick={() => Modal.confirm(payModalConfirmConfig)}
                className='button-close-x'
              >
                <CloseOutlined style={{ fontSize: 16 }} />
              </Button>
            }
          </div>
          <div className='rp-paybutton-detail-view-card__header'>
            <div className='rp-paybutton-detail-view-card__header__logo-group'>
              <div className='rp-paybutton-detail-view-card__header__logo-group__left'>
                <div className='rp-paybutton-detail-view-card__header__logo-item --radical-pay'>
                  <img
                    src={logoBlueIcon}
                    alt="icon"
                    height="100%"
                  />
                </div>
              </div>
              <div className='rp-paybutton-detail-view-card__header__logo-group__right'>
                <div className='rp-paybutton-detail-view-card__header__logo-item --merchant'>
                  {logo && Object.keys(logo).length > 0 &&
                    <>
                      <div className='merchant-logo'>
                        <img
                          src={logo?.url}
                          alt={logo?.name}
                          height="100%"
                        />
                      </div>
                      <p className="mb-0">{merchant?.name}</p>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className='rp-paybutton-detail-view-card__header__title'>
              {title}
            </div>
            <div className='rp-paybutton-detail-view-card__header__desc'>
              {description}
            </div>
          </div>

          <div className='rp-paybutton-detail-view-card__main'>
            {!!paymentChannel && formFields?.length > 0 &&
              <>
                {formFields.map(field => renderFieldByType(field))}

                <RPButton
                  gradient
                  center
                  withArrowIcon
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Pay Now
                </RPButton>
              </>
            }
          </div>

          <div className='rp-paybutton-detail-view-card__footer'>
            <div className='rp-paybutton-detail-view-card__footer__tac'>
              <div className='title'>Terms & Conditions</div>
              <div className='desc'>
                {termAndConditions || defaultTAC}
              </div>
            </div>
            <div className='rp-paybutton-detail-view-card__footer__contact-information'>
              <div className='title'>Contact Information</div>
              <div className='info-container'>
                <div>{supportEmail}</div>
                {!!supportContactNumber &&
                  <div>{`+${supportContactNumber}`}</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

OrderFormContainer.propTypes = {
  showCloseBtnX: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  setOrderId: PropTypes.func.isRequired,
  orderFormData: PropTypes.object.isRequired,
  setOrderFormData: PropTypes.func.isRequired,
  setPayInformationForm: PropTypes.func.isRequired,
  setShowPaymentMethods: PropTypes.func.isRequired,
  currentMode: PropTypes.oneOf(['Live', 'Test']).isRequired,
  formFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  refType: PropTypes.oneOf(['PaymentButton', 'PaymentPage', 'PaymentLink']).isRequired,
};

export default OrderFormContainer;
