/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import { Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import PaymentMethod from '@/components/PaymentMethod';
import {
  paymentUtil,
  useBeforeRender,
} from '@/utils';
import { paymentPageService, transactionService } from '@/services';
import Deactivated from '@/components/PaymentPages/Deactivated';
import OrderFormContainer from '@/containers/OrderContainer/form';
import {
  PAYMENT_PAGES_STATUSES,
} from '@/constants';

function ViewContainer() {
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [paymentPageData, setPaymentPageData] = useState({});
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [payInformationForm, setPayInformationForm] = useState({
    email: null,
    amount: null,
    phoneNumber: null,
  });
  const [isInactived, setIsInactived] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [orderFormData, setOrderFormData] = useState({});
  const [orderFormFields, setOrderFormFields] = useState([]);
  const [currentMode, setCurrentMode] = useState(null);

  // Add inputName to fields and sort fields by position
  useEffect(() => {
    let fields = [];
    const {
      items: payItemsFields,
      customerDetail: payCustomerDetailFields,
    } = paymentPageData;
    if (payItemsFields) {
      fields = [
        ...fields,
        ...paymentUtil.addInputNameToFields(payItemsFields),
      ];
    }
    if (payCustomerDetailFields) {
      fields = [
        ...fields,
        ...paymentUtil.addInputNameToFields(payCustomerDetailFields),
      ];
    }
    fields = fields.sort((firstItem, secondItem) => firstItem.position - secondItem.position);
    setOrderFormFields(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentPageData]);

  const initData = async () => {
    const orderData = JSON.parse(sessionStorage.getItem(`pay${id}`));
    if (!!orderData && orderData.id) {
      setPayInformationForm({
        email: orderData.email,
        phoneNumber: orderData.contactNumber,
        amount: orderData.amount / 100,
      });
      setOrderId(orderData.id);
      setShowPaymentMethods(true);
    }

    let dataResponse = await paymentPageService.getPaymentPagesDetails({ id });

    if (!dataResponse) {
      return;
    }
    dataResponse = {
      ...dataResponse,
      items: dataResponse?.items.map(item => ({
        ...item,
        amount: item.amount / 100
      }))
    };
    setPaymentPageData(dataResponse);
    setIsInactived(dataResponse.status === PAYMENT_PAGES_STATUSES.inactive);
    setCurrentMode(dataResponse.workMode);
    setTimeout(() => setPageLoading(false), 600);
  };

  useBeforeRender(() => initData(), []);

  const handleCreateOrder = (payload) => paymentPageService.createOrder(payload);

  const handleCreateTransaction = ({ paymentMethod, paymentInformation }) => {
    const payload = {
      orderId,
      paymentMethod,
      paymentInformation,
    };
    return transactionService.createTransactions(payload);
  };

  const handleBackToOrderForm = () => {
    sessionStorage.removeItem(`pay${id}`);
    setShowPaymentMethods(false);
  };

  return (
    <div className="rp__payment-pages__view">
      <Spin
        spinning={pageLoading}
        className="loading-spiner"
        indicator={
          <Loading3QuartersOutlined spin style={{ fontSize: 36 }} />
        }
      />
      {isInactived && !pageLoading &&
        <Deactivated
          supportEmail={paymentPageData?.supportEmail}
          supportContact={paymentPageData?.supportContactNumber}
          merchantBussinessName={paymentPageData?.merchant?.name}
        />
      }
      {!isInactived && !pageLoading &&
        <div className="rp__payment-pages__view-container">
          <div className="rp__payment-pages__view-slice" />
          <div className="rp__payment-pages__view-main">
            {!showPaymentMethods &&
              <OrderFormContainer
                data={paymentPageData}
                onFinish={handleCreateOrder}
                setShowPaymentMethods={setShowPaymentMethods}
                setPayInformationForm={setPayInformationForm}
                orderFormData={orderFormData}
                formFields={orderFormFields}
                setOrderFormData={setOrderFormData}
                setOrderId={setOrderId}
                refType="PaymentPage"
                currentMode={currentMode}
              />
            }
            {showPaymentMethods && orderId &&
              <PaymentMethod
                orderId={orderId}
                email={payInformationForm.email}
                amount={payInformationForm.amount}
                phoneNumber={payInformationForm.phoneNumber}
                onFinish={handleCreateTransaction}
                merchantLogo={paymentPageData.logo}
                merchantName={paymentPageData.merchant.name}
                setShowPaymentMethods={setShowPaymentMethods}
                backToOrderForm={handleBackToOrderForm}
                currentMode={currentMode}
              />
            }
          </div>
        </div>
      }
    </div>
  );
}

export default ViewContainer;
