import React from 'react';

import spImage from '@/assets/images/payment-pages/sp.svg';
import './NotFound.scss';

function NotFound() {
  return (
    <div className="payment-pages-not-found-container">
      <div className="payment-pages-not-found__sp-image">
        <img
          src={spImage}
          alt="sp"
          width="100%"
          height="100%"
        />
      </div>
      <div className='payment-pages-not-found__content'>
        <p>Oops, look like</p>
        <p>this payment page does not exist...</p>
      </div>
    </div>
  );
}

export default NotFound;
