import {
  uniqueId,
  camelCase,
} from 'lodash';
import { PAYMENT_PAGES_INPUT_TYPES } from '@/constants';

export const parseCheckoutOrder = ({
  refId,
  values,
  refType,
  checkoutId,
  orderInformationFields,
}) => {
  let result = {};
  let newItems = [];
  let customerInfos = [];

  // Parse Order Data for Payment Page and Payment Button
  if (refType === 'PaymentButton' || refType === 'PaymentPage') {
    orderInformationFields.forEach(field => {
      const {
        id,
        label,
        name: inputName,
      } = field;

      if (id) {
        let amount = values[inputName] || 0;
        amount = Number((amount * 100).toFixed());
        newItems = [
          ...newItems,
          {
            refId: id,
            refType: `${refType}Item`,
            quantity: 1,
            amount,
            unitPrice: amount,
          }
        ];
      } else {
        customerInfos = [
          ...customerInfos,
          {
            label,
            value: values[inputName],
          }
        ];
      }
    });

    result = {
      refId,
      refType,
      checkoutId,
      customerInfos,
      items: newItems,
    };
  }

  // Parse Order Data for Payment Link
  if (refType === 'PaymentLink') {
    orderInformationFields.forEach(field => {
      const {
        id,
        name: inputName,
        type: fieldType,
      } = field;
      let { label } = field;

      if (id) {
        return;
      }

      if (fieldType === PAYMENT_PAGES_INPUT_TYPES.customerDetailFieldType['phone-number']) {
        label = 'Phone';
      }

      customerInfos = [
        ...customerInfos,
        {
          label,
          value: values[inputName],
        }
      ];
    });
    result = {
      refId,
      refType,
      checkoutId,
      customerInfos,
    };
  }
  return result;
};

export const addInputNameToFields = fields => (
  fields.map(field => {
    const {
      id,
      label,
      optional,
      type: fieldType,
      default: fieldDefault,
    } = field;

    let inputName = uniqueId(`${camelCase(label)}`);

    if (id && fieldType === PAYMENT_PAGES_INPUT_TYPES.paymentItemType['fixed-amount'] && !optional) {
      inputName = 'amount';
    }

    if (fieldDefault && fieldType === PAYMENT_PAGES_INPUT_TYPES.customerDetailFieldType.email) {
      inputName = 'email';
    }

    if (fieldDefault && fieldType === PAYMENT_PAGES_INPUT_TYPES.customerDetailFieldType['phone-number']) {
      inputName = 'phoneNumber';
    }

    return (
      {
        ...field,
        name: inputName
      }
    );
  })
);
