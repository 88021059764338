import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker,
} from 'antd';
import {
  uniqueId
} from 'lodash';

import './index.scss';

function RPDatePicker({
  label,
  value,
  required,
  onChange,
  ...props
}) {
  const [datePickerKlass, setDatePickerKlass] = useState(value ? '--has-value' : '');
  const rpDatePickerIdName = uniqueId('rp-date-picker-');
  const [dateValueSelected, setDateValueSelected] = useState(value);

  const handleOnChange = (date, dateString) => {
    if (date) {
      setDatePickerKlass('--has-value');
      setDateValueSelected(date);
    } else {
      setDatePickerKlass('');
      setDateValueSelected();
    }
    onChange && onChange(date, dateString);
  };

  return (
    <div
      className={`rp-date-picker-container ${datePickerKlass}`}
      id={rpDatePickerIdName}
    >
      <DatePicker
        {...props}
        value={dateValueSelected}
        onChange={handleOnChange}
        getPopupContainer={() => document.getElementById(`${rpDatePickerIdName}`)}
      />
      {!!label && (
        <label htmlFor='#' className="custom-input__label">
          {label}
          {!!required && (<span className="custom-input__required">*</span>)}
        </label>
      )
      }
    </div>
  );
}

RPDatePicker.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default RPDatePicker;
