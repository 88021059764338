import React from 'react';
import PropTypes from 'prop-types';
import {
  Form
} from 'antd';
import { uniqueId } from 'lodash';

import RPInputAmount from '@/components/RPInputAmount';
import { PAYMENT_PAGES_INPUT_TYPES } from '@/constants';

function PaymentItemField({
  fieldData,
  inputName,
}) {
  const {
    label,
    amount,
    type: fieldType,
  } = fieldData;

  if (fieldType === PAYMENT_PAGES_INPUT_TYPES.paymentItemType['fixed-amount']) {
    return (
      <Form.Item
        key={uniqueId()}
        name={inputName}
        rules={[
          { required: true, message: `Please input ${label}` },
          () => ({
            validator(_, value) {
              if (value && value <= 0) {
                return Promise.reject(
                  new Error(`${label} has greater than 0`),
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
        wrapperCol={{ span: 24 }}
      >
        <RPInputAmount
          name={inputName}
          disabled
          prefix="INR"
          label={label}
          value={amount}
          placeholder={label}
          required
          className="rp-paybutton-detail-view-card__main__input"
        />
      </Form.Item>
    );
  }

  if (fieldType === PAYMENT_PAGES_INPUT_TYPES.paymentItemType['customers-decide-amount']) {
    return (
      <Form.Item
        key={uniqueId()}
        name={inputName}
        rules={[
          { required: true, message: `Please input ${label}` },
          () => ({
            validator(_, value) {
              if (value && value <= 0) {
                return Promise.reject(
                  new Error(`${label} has greater than 0`),
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
        wrapperCol={{ span: 24 }}
      >
        <RPInputAmount
          required
          label={label}
          placeholder={label}
          prefix="INR"
          className="rp-paybutton-detail-view-card__main__input"
        />
      </Form.Item>
    );
  }

  return null;
}

PaymentItemField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  inputName: PropTypes.string.isRequired,
};

export default PaymentItemField;
