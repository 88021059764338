import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
} from 'antd';

import './index.scss';

const { TextArea } = Input;

const RPTextArea = forwardRef((
  {
    label,
    required,
    className,
    ...props
  },
  ref
) => (
  <div className={`${className || ''} rp-textarea-container`}>
    <TextArea
      {...props}
      ref={ref}
      autoSize={{
        minRows: 2,
      }}
    />
    <div className="hover-style" />
    {!!label && (
      <label htmlFor='#' className="custom-input__label">
        {label}
        {!!required && (<span className="custom-input__required">*</span>)}
      </label>
    )}
  </div>
));

RPTextArea.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
};

export default RPTextArea;
