import {
  generatePath,
} from 'react-router-dom';
import http from '@/http';
import {
  parse,
} from '@/utils';
import { transactionAPIs } from '@/constants/api';
import { PAYMENT_METHODS_AVAILABLE } from '@/constants';

// GET /transactions/:id
export async function getTransaction(id) {
  try {
    const url = generatePath(transactionAPIs.get, { id });
    const response = await http.get(url);
    const { status, data } = parse.camelize(response);

    if (status === 200) {
      return {
        success: true,
        data,
      };
    }

    return {
      success: false,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};

const createTransactionPayload = ({ orderId, paymentMethod, paymentInformation }) => {
  let payload = { orderId };
  switch (paymentMethod) {
    case PAYMENT_METHODS_AVAILABLE.CREDIT_DEBIT:
      payload = {
        ...payload,
        paymentMethod,
        cardInfo: paymentInformation,
      };
      break;
    case PAYMENT_METHODS_AVAILABLE.UPI:
      payload = {
        ...payload,
        paymentMethod,
        upiInfo: paymentInformation
      };
      break;
    case PAYMENT_METHODS_AVAILABLE.NETBANKING:
      payload = {
        ...payload,
        paymentMethod,
        bankInfo: paymentInformation
      };
      break;
    default:
      break;
  }
  if (Object.keys(payload).length === 0) {
    return null;
  }

  return payload;
};

export async function createTransactions({
  orderId,
  paymentMethod,
  paymentInformation,
}) {
  try {
    const payload = createTransactionPayload({ orderId, paymentMethod, paymentInformation });
    const response = await http.post(`${transactionAPIs.base}`, parse.snakelize(payload));
    const { status, data } = parse.camelize(response);

    if (status === 200) {
      return {
        success: true,
        data,
      };
    }

    return {
      success: false,
    };

  } catch (error) {
    const { response } = error;

    if (response.status === 400) {
      const {
        reason,
        metadata,
      } = parse.camelize(response.data);

      return {
        success: false,
        reason,
        errors: {
          ...metadata,
        },
      };
    }
    return {
      success: false,
    };
  }
}

export async function getSupportedBank(mode) {
  try {
    const url = `${transactionAPIs.supportedBanks}?work_mode=${mode}`;
    const response = await http.get(url);
    const { status, data } = parse.camelize(response);

    if (status === 200) {
      return {
        success: true,
        data,
      };
    }

    return {
      success: false,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
}
